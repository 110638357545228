import React, {useContext, useEffect, useState} from "react";
import AppLayout from "../../components/layout/AppLayout";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import {useChatClient} from "../../hooks/useChatClient";
import {AppContext} from "../../context/AppContext";
import ChatIcon from '../../images/unread-icon.svg';



export const Container = styled.div`
  border-radius: 5px;
  background: ${AppTheme.header.colors.whiteColor};
  float: left;
  width: 100%;
  padding: 0;
  height:calc(100vh - 40px);
  > form {
    background: ${AppTheme.header.colors.whiteColor};
    padding: 0px 15px 20px 15px;
  }
`;

export const GridBoxes = styled.div`
  width:100%;
  padding:20px;
  >a{
    width:33.3%;
    cursor:pointer;
    text-decoration:none;
    display:block;
    >.col{
        display: flex;
        padding: 24px 18px;
        flex-direction: row;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 12px 12px 0px 0px;
        box-shadow:0px 1px 2px 0px rgba(16, 24, 40, 0.06),0px 1px 3px 0px rgba(16, 24, 40, 0.10);
        background:${AppTheme.header.colors.whiteColor};
        >img{
            margin-right:12px;
        }
        >span{
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            color:${AppTheme.header.colors.blackColor};
            text-align:left;
        }
    }
    >.back-col{
        background:${AppTheme.header.colors.blueColor};
        display: flex;
        padding: 16px 18px;
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
        align-self: stretch;
        justify-content:flex-end;
        border-radius: 0px 0px 12px 12px;
        >span{
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            color:${AppTheme.header.colors.whiteColor};
        }
    }
  }
`;

const UnreadMessages = () => {
    const {data, setData} = useContext(AppContext);

    const {unread} = data;

    if(!unread || unread.loading){
        return  <progress/>
    }
    return <GridBoxes>
        <a href={'/chats'}>
            <div className="col">
                <img src={ChatIcon} />
                <span>{unread.total} Unread Messages</span>
            </div>
            <div className="back-col">
                <span>View All Chats</span>
            </div>
            {/* You have <br/>
             from {unread.channels.length} conversations */}
        </a>
    </GridBoxes>
}

const CreateUser = () => {
    return (
        <AppLayout>
            <Container>
                <UnreadMessages/>
            </Container>
        </AppLayout>
    );
}

export default CreateUser;
