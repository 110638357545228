import React, {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../../helpers/Api";
import {AppContext} from "../../context/AppContext";

const AuthCheck = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/auth/login');
            return;
        }
        Api.get('/auth/profile')
            .then(() => {
                navigate('/dashboard');
            })
            .catch(() => {
                navigate('/auth/login')
            });
    }, []);

    return (
        <div>
            Loading..
        </div>
    );
}

export default AuthCheck;
