import React, {useEffect, useState} from 'react';
import Api from "../../helpers/Api";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import CircleIcon from '../../images/dark-circle.svg';
import DarkLine from '../../images/dark-line.svg';
import SubHeader from "../../components/subheader/SubHeader";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  > .react-tabs {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    > .react-tabs__tab-panel {
      width: calc(100% - 300px);
      padding: 0 30px;
      border-left: 1px solid rgba(9, 30, 66, 0.141176);
    }
  }

  > .tab-content {
    flex: 3;
    padding: 20px;
    overflow-y: auto;
  }
`;

export const UserTabs = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  border-right: 1px solid rgba(9, 30, 66, 0.141176);
  height: 100vh;
  overflow-y: auto;
  padding-left: 15px;

  > .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    align-items: flex-start;
    width: 100%;
    border: none;

    > .react-tabs__tab {
      list-style: none;
      padding: 0px 10px;
      cursor: pointer;
      border-bottom: none;
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      background: transparent;
      border-radius: 6px;
      width: 248px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: ${AppTheme.header.colors.blackColor};
      border: none;
      margin-bottom: 10px;
    }

    > .react-tabs__tab--selected {
      background: ${AppTheme.header.colors.blueColor};
      border-radius: 6px;
      color: ${AppTheme.header.colors.whiteColor};
    }
  }

`;

export const ItineraryContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  padding: 0px 15px;

  .tab-status {
    width: 100%;
    padding-top: 20px;

    > .react-tabs {
      > .react-tabs__tab-list {
        border-bottom: none;
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > .react-tabs__tab--selected {
          background: ${AppTheme.header.colors.blueColor} !important;
          border-color: ${AppTheme.header.colors.blueColor} !important;
          color: ${AppTheme.header.colors.whiteColor} !important;
        }

        .react-tabs__tab {
          display: inline-block;
          border: 1px solid ${AppTheme.header.colors.inputBorder};
          margin: 0 10px 0 0;
          bottom: -1px;
          position: relative;
          list-style: none;
          padding: 6px 20px;
          cursor: pointer;
          border-radius: 30px;
          color: ${AppTheme.header.colors.blackColor};
          font-weight: 500;
          background: transparent;
        }
      }

      > .react-tabs__tab-panel {
        .status-content {
          width: 100%;

          > .detailed-items {
            padding: 20px 0 15px 0px;

            > .main-info-col {
              width: 100%;
              border-radius: 12px;
              border: 1px #EAECF0;
              background: ${AppTheme.header.colors.whiteColor};
              box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
              padding: 20px 30px;

              > .edit-items-col {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                > .col {
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  width: 100%;
                  margin-bottom: 8px;

                  > span {
                    color: ${AppTheme.header.colors.blueColor};
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    min-width: 120px;
                    display: block;
                    text-align: left;
                  }

                  > p {
                    color: ${AppTheme.header.colors.blackColor};
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px;
                    margin-bottom: 0px;
                    text-align: left;
                  }
                }

                .editable-items {
                  width: 80px;

                  > a {
                    background: ${AppTheme.header.colors.blueColor};
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: -0.14px;
                    color: #ffffff;
                    padding: 0 10px;
                    text-decoration: none;
                    border-radius: 6px;
                  }
                }
              }

              > .col {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 8px;

                > span {
                  color: ${AppTheme.header.colors.blueColor};
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  min-width: 120px;
                  display: block;
                  text-align: left;
                }

                > p {
                  color: ${AppTheme.header.colors.blackColor};
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 28px;
                  margin-bottom: 0px;
                  text-align: left;
                }
              }
            }

            > .event-list {
              padding: 15px 0;

              > span {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                color: ${AppTheme.header.colors.blackColor};
                border-bottom: 1px solid #cccccc;
                padding: 5px 0;
                display: block;
                width: 100%;
                text-align: left;
              }

              > .event-list-info {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                padding-bottom: 20px;
                position: relative;

                .circle-line {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: flex-start;
                  position: absolute;
                  left: 0px; /* Adjust based on your design */
                  top: 0;
                  height: 100%;

                  .circle-icon {
                    width: 15px; /* Adjust based on your design */
                    height: 15px; /* Adjust based on your design */
                    background: url(${CircleIcon});
                    background-size: cover !important;
                  }

                  .line-pic {
                    flex: 1;
                    width: 2px; /* Adjust based on your design */
                    background: url(${DarkLine});

                  }
                }

                > .meet-information {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  width: 100%;
                  padding-left: 40px;
                  height: 100%;

                  > span {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    color: ${AppTheme.header.colors.blackColor};
                    border-bottom: 1px solid #cccccc;
                    padding: 5px 0;
                    display: block;
                    width: 100%;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  > .user-name {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      color: ${AppTheme.header.colors.blackColor};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      padding-bottom: 0px;
    }

    > a {
      text-decoration: none;
      color: ${AppTheme.header.colors.whiteColor};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      background: ${AppTheme.header.colors.blueColor};
      height: 36px;
      display: flex;
      align-items: center;
      justify-comtent: center;
      padding: 0px 20px;
      border-radius: 4px;
    }

  }

  > .detailed-items {
    padding: 15px 0;

    > .main-info-col {
      width: 100%;
      border-radius: 12px;
      border: 1px #EAECF0;
      background: ${AppTheme.header.colors.whiteColor};
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      padding: 20px 30px;

      > .edit-items-col {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        > .col {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 8px;

          > span {
            color: ${AppTheme.header.colors.blueColor};
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            min-width: 120px;
            display: block;
            text-align: left;
          }

          > p {
            color: ${AppTheme.header.colors.blackColor};
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 0px;
            text-align: left;
          }
        }

        .editable-items {
          width: 80px;

          > a {
            background: ${AppTheme.header.colors.blueColor};
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.14px;
            color: #ffffff;
            padding: 0 10px;
            text-decoration: none;
            border-radius: 6px;
          }
        }
      }

      > .col {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 8px;

        > span {
          color: ${AppTheme.header.colors.blueColor};
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          min-width: 120px;
          display: block;
          text-align: left;
        }

        > p {
          color: ${AppTheme.header.colors.blackColor};
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin-bottom: 0px;
          text-align: left;
        }
      }
    }

    > .event-list {
      padding: 15px 0;

      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: ${AppTheme.header.colors.blackColor};
        border-bottom: 1px solid #cccccc;
        padding: 5px 0;
        display: block;
        width: 100%;
        text-align: left;
      }

      > .event-list-info {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 20px;
        position: relative;

        .circle-line {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: absolute;
          left: 0px; /* Adjust based on your design */
          top: 0;
          height: 100%;

          .circle-icon {
            width: 15px; /* Adjust based on your design */
            height: 15px; /* Adjust based on your design */
            background: url(${CircleIcon});
            background-size: cover !important;
          }

          .line-pic {
            flex: 1;
            width: 2px; /* Adjust based on your design */
            background: url(${DarkLine});

          }
        }

        > .meet-information {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          padding-left: 40px;
          height: 100%;

          > span {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: ${AppTheme.header.colors.blackColor};
            border-bottom: 1px solid #cccccc;
            padding: 5px 0;
            display: block;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
`;

function SingleItinerary({itinerary}) {
    return (
        <div className='detailed-items'>

            <div className='main-info-col'>
                <div className='edit-items-col'>
                    <div className='col'>
                        <span>Title</span>
                        <p>{itinerary.title}</p>
                    </div>
                    <div className='editable-items'>
                        <a href={`/itineraries/${itinerary.id}/edit`}>Edit</a>
                    </div>
                </div>
                <div className='col'>
                    <span>Location</span>
                    <p>{itinerary.location}</p>
                </div>
                <div className='col'>
                    <span>Date</span>
                    <p>{itinerary.from} To {itinerary.to}</p>
                </div>
                <div className='col'>
                    <span>Description</span>
                    <p>{itinerary.description}</p>
                </div>
            </div>
            <div className='event-list'>
                {itinerary.events.map(event => {
                    const dates = [];

                    const fromDate = new Date(event.from).toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'});
                    const toDate = new Date(event.to).toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'});
                    const fromHourString = new Date(event.from).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', dates: 'numeric'});
                    const toHourString = new Date(event.to).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric'});

                   if(event.allDay && event.from){
                        dates.push(fromDate + ' ' +fromHourString +' (All Day)' );
                   }else {
                       if(event.from) {
                           dates.push(fromDate + ' ' + fromHourString);
                       }
                       if(event.to) {
                           dates.push(toDate + ' ' + toHourString);
                       }
                   }

                    return (
                        <div className='event-list-info' key={event.id}>
                            <div className='circle-line'>
                                <div className='circle-icon'></div>
                                <div className='line-pic'></div>
                            </div>
                            <div className='meet-information'>
                                {event.type &&
                                    <span>{event.type}</span>
                                }
                                {event.title &&
                                    <span>{event.title}</span>
                                }

                                <span>{dates.join(' to ')}</span>

                                {event.details &&
                                    <span style={{marginBottom: 20, borderBottom: 'none'}}>{event.details}</span>
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function UserItineraries({user, upcomingItineraries, pastItineraries}) {
    const [selectedNewIndex, setSelectedNewIndex] = useState(0);
    return (
        <ItineraryContainer>
            <div className='user-name'>
                <span>{`${user.firstName || ''} ${user.firstName || ''}`.trim() || user.email}</span>
            </div>
            <div className='tab-status'>
                <Tabs selectedIndex={selectedNewIndex} onSelect={index => setSelectedNewIndex(index)}>
                    <TabList>
                        <Tab>Upcoming</Tab>
                        <Tab>Past</Tab>
                    </TabList>
                    <TabPanel>
                        <div className='status-content'>
                            {upcomingItineraries.map(itinerary => {
                                return <SingleItinerary key={itinerary.id} itinerary={itinerary}/>;
                            })}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='status-content'>
                        {pastItineraries.map(itinerary => {
                            return <SingleItinerary key={itinerary.id} itinerary={itinerary}/>;
                        })}
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </ItineraryContainer>
    );
}

const ListChats = () => {
    const {id,entity} = useParams();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search] = useSearchParams();

    const filters = JSON.parse(search.get('filters'));

    const navigate = useNavigate();

    const listUsers = () => {
        setLoading(true);
        const endpoint = '/itineraries/admin';
        Api.get(`${endpoint}`, filters).then(response => {
            setUsers(response);
        }).catch((e) => {
            console.log(e);
            alert('Error');
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        listUsers();
    }, []);

    if (loading) {
        return <progress/>;
    }

    if (users.length === 0) {
        return <div>No users found</div>;
    }

    let index = users.findIndex(user => user.user.id === parseInt(id));
    if(index === -1){
        index = 0;
    }
    return (
        <Container>
            <Tabs selectedIndex={index} onSelect={index=>{
                navigate('/itineraries/'+users[index].user.id);
            }}>
                <UserTabs>
                    <TabList>
                        {users.map((item, index) => {
                            console.log('Tab index', item.user.id);
                            return <Tab key={index}>{`${item.user.firstName || ''} ${item.user.lastName || ''}`.trim() || item.user.email}</Tab>
                        })}
                    </TabList>
                </UserTabs>
                {users.map((item, index) => (
                    <TabPanel key={index}>
                        <UserItineraries user={item.user} pastItineraries={item.itineraries.past} upcomingItineraries={item.itineraries.upcoming}/>
                    </TabPanel>
                ))}
            </Tabs>
        </Container>
    );
};

export default ListChats;
