import React from "react";
import Form from "../../components/form/Form";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import AddIcon from '../../images/add-more-blue.svg';

export const AddContainer = styled.div`
  cursor: pointer;

  > div {
    > form {
      > div {
        > div {
          > div {
            border-radius: 2px;
            height: 50px;
            background: transparent;
            border: 1px solid ${AppTheme.header.colors.inputBorder};

            > input {
              border-radius: 2px;
              height: 50px;
              background: transparent;
            }
          }

          > .simple-input {
            border-radius: 2px;
            height: 50px;
            background: transparent;
            border: 1px solid ${AppTheme.header.colors.inputBorder};

            > input {
              border-radius: 2px;
              height: 50px;
              background: transparent;
            }
          }
        }
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > .delete-button {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 3px;
    padding-bottom: 10px;
    color: ${AppTheme.header.colors.errorTextColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    border: none;
    padding: 0;
    height: auto;
    background: transparent;
    margin-bottom: 10px;
  }

  > .add-more {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${AppTheme.header.colors.blueColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding-top: 7px;
    cursor: pointer;
    padding: 0;
    height: auto;
    background: transparent;
    border: none;

    > img {
      margin-right: 8px;
    }
  }
`;

export const MoreContainer = styled.div`
  .add-blocks {
    > div {
      > div {
        > div {
          > div {
            > .simple-input {
              border-radius: 2px;
              height: 50px;
              background: transparent;
              border: 1px solid ${AppTheme.header.colors.inputBorder};

              > input {
                border-radius: 2px;
                height: 50px;
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
`;

const EventBlocks = ({value, onChange}) => {
    const data = value.events || [];
    console.log({data})
    return (
        <MoreContainer>
            {data.map((block, index) => {
                return <div className="add-blocks"><EventBlock key={`block-${index}`} event={block} onChange={(d) => {
                    const v = [...data];
                    v[index] = d;
                    console.log({d})
                    onChange(v);
                }}/>
                    <ButtonContainer>
                        <button type={'button'} onClick={() => {
                            const v = [...data];
                            v.splice(index, 1);
                            onChange(v);
                        }}
                                className="delete-button"
                        >Remove
                        </button>
                    </ButtonContainer>
                </div>
            })}
            <ButtonContainer>
                <button type={'button'} onClick={() => {
                    onChange([...data, {from: '', to: '', title: '', details: '', allDay: false}]);
                }} type="button" className="add-more"><img src={AddIcon}/> Add Block
                </button>
            </ButtonContainer>
        </MoreContainer>
    );
}

const EventBlock = ({event, onChange}) => {
    return (
        <AddContainer>
            <Form fields={[
                {
                    name: 'type',
                    label: 'Type',
                    type: 'select',
                    options: {
                        options: ['Meeting', 'Call', 'Flight', 'Travel','Ground Transportation', 'Hotel', 'Other'].map(option => ({
                            label: option,
                            value: option
                        }))
                    }
                },
                {name: 'title', label: 'Title', type: 'text'},
                {name: 'from', label: 'From', type: 'datetime', required: true},
                {name: 'allDay', label: 'All Day', type: 'boolean', required: true},
                {name: 'to', label: 'To', type: 'datetime', required: true, dependsOn: 'allDay', dependsOnValue: false},
                {name: 'details', label: 'Details', type: 'textarea'},
                {name: 'attachments', label: 'Attachments', type: 'attachments'},
            ]} data={event} handleChange={onChange} hideSubmit={true} renderFormTag={false}/>
        </AddContainer>
    );
}
export default EventBlocks;
