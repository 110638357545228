import React, {useContext, useState} from "react";
import Form from "../../components/form/Form";
import Api from "../../helpers/Api";
import AuthLayout from "../../components/layout/AuthLayout";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import {AppContext} from "../../context/AppContext";

export const Container = styled.div`
  width:100%;
  float:left;
  padding: 30px;
`;

export const Heading = styled.div`
  margin-bottom: 16px;

  > span {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    color: ${AppTheme.header.colors.blackColor};
    text-align:left;
    display:block;
  }
`;
export const FormContainer = styled.div`
  >div{
    >form{
      >div{
        >.simple-button{
          width:100%;
          justify-content:center;
        }
      }
    }
  }
`;

const Login = () => {

    const {data, setData} = useContext(AppContext);

    const [formData, setFormData] = useState({email: '', password: ''});
    const [loading, setLoading] = useState(false)

    const fields = [
        {name: 'email', label: 'Email', type: 'email', placeholder: 'Enter email'},
        {name: 'message', label: 'Message', type: 'textarea' ,placeholder: 'Type your message'}
    ];
    const [done, setDone] = useState(false);
    if(done){
        return (
            <AuthLayout title='Contact Us' description={'Please enter your message below'}>
                <Container>
                    <FormContainer>
                        <div>
                            <span>Thank you for your message. We will get back to you shortly.</span>
                        </div>
                    </FormContainer>
                </Container>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout title='Contact Us' description={'Please enter your message below'}>
            <Container>
                <FormContainer>
                    <Form
                        endpoint={'/contact'}
                        onCreate={()=>{
                            setDone(true);
                        }}
                        fields={fields}
                        data={formData}
                        handleChange={d => setFormData(d)}
                    />
                </FormContainer>
            </Container>
        </AuthLayout>
    );
}

export default Login;
