import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import AppTheme from '../../../../../themes/AppTheme';

export const NavBar = styled.ul`
 margin:0;
 padding:10px 0;
 list-style:none;
 display:flex;
 flex-direction:column;
 justify-content:flex-start;
 flex:1;
 max-height:calc(100vh - 200px);
 overflow:auto;
`;

export const NavBarItem = styled.li`
    padding:0px 14px;
    // border-bottom:1px solid #C1C7D0;
    &.active {
        >a{
            background-color: #E9F2FF;
            border-radius: 3px;
            padding: 8px;
            >span{
                color:${({ theme }) => theme.buttonPrimary};
                font-weight: 600;
            }
            >.inactive-icons{
                display:none;
            }
            >.active-icons{
                display:flex;
                width:20px;
            }           
        }
        >.sidebar-drop{
            li.active{
                a{
                    background: #E9F2FF;
                    border-radius: 3px;
                    height:40px;
                    display:flex;
                    align-items:center;
                    justify-content:flex-start;
                    > span{
                        color:${({ theme }) => theme.buttonPrimary};
                    }
                }
            }
        }
    }
    >a{
        text-decoration:none;
        height:40px;
        display:flex;
        align-items:center;
        width:100%;
        padding:8px;
        >.active-icons{
            display:none;
        }
        >.inactive-icons{
            display:flex;
            width:20px;
        }
    }
    &:last-child{
        border-bottom:none;
    }
`;

export const NavBars = styled.ul`
    margin:20px 8px 0px ;
    padding:0px;
    list-style:none;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    flex:1;
    max-height:calc(100vh - 200px);
    overflow:auto;
`;

export const NavBarItems = styled.li`
    padding: 0px 14px 0px;
    &.active {
        >a{
            >span{
                color:${({ theme }) => theme.navLink};
            }
            >.inactive-icons{
                display:none;
            }
            >.active-icons{
                display:flex;
                width:20px;
            }           
        }
    }
    >a{
        text-decoration:none;
        height:40px;
        display:flex;
        align-items:center;
        width:100%;
        >.active-icons{
            display:none;
        }
        >.inactive-icons{
            display:flex;
            width:20px;
        }
    }
    &:last-child{
        border-bottom:none;
    }
`;

export const LinkTitle = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color:${AppTheme.header.colors.whiteColor};
    @media screen and (max-width: 1240px) {
        font-size:13px;
    }
`;

export const InactiveIcon = styled.img`
    margin-right:10px;
`;

export const ActiveIcon = styled.img`
    margin-right:10px;
`;

const NavigationItems = (props) => {

    const getClassNames = (defaultClass, active) => {
        return classNames(defaultClass, { active: active });
    };

    return (
        <>
            <Link
                to={props.url}
                onClick={(e) => {
                    if (props.url) {
                        e.stopPropagation();
                    }
                }}
                className={getClassNames('nav-item1', props.isActive)}
            >
                {props.inactiveIcon &&
                    <InactiveIcon src={props.inactiveIcon} alt="Icon" className=" inactive-icons" />
                }
                {props.activeIcon &&
                    <ActiveIcon src={props.activeIcon} alt="Icon" className=" active-icons" />
                }
                {props.isActive && (
                    <LinkTitle>
                        {props.tabname} {props.url}
                    </LinkTitle>
                )}
                {!props.isActive && <LinkTitle>{props.tabname}</LinkTitle>}

            </Link>
            
        </>
    )
}

export default NavigationItems;