import React, {useContext, useEffect, useState} from 'react';
import {
    Channel,
    ChannelList,
    ChannelPreview,
    Chat,
    LoadingIndicator,
    MessageInput,
    MessageList,
    Thread,
    Window,
} from 'stream-chat-react';


import 'stream-chat-react/dist/css/v2/index.css';
import {useChatClient} from "../../hooks/useChatClient";
import {AppContext} from "../../context/AppContext";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import {useSearchParams} from "react-router-dom";

export const ChatContainer = styled.div`
  display: flex;

  > .str-chat.str-chat__theme-light.str-chat-channel-list.str-chat__channel-list.str-chat__channel-list-react {
    width: 280px;
  }

  > .str-chat.str-chat__theme-light.str-chat-channel.str-chat__channel {
    width: calc(100% - 280px);
    padding-bottom: 20px;
  }

`;

export const AllMessages = styled.div`
  width: 280px;

  > div {
    > div {
      > div {
        > button {
          > div {
            > .str-chat__avatar {
              > .str-chat__avatar-fallback {
                background: #B54F3E;
              }
            }
          }
        }
      }
    }
  }
`;

export const Header = styled.div`
  > div {
    > .str-chat__avatar {
      > .str-chat__avatar-fallback {
        background: #B54F3E;
      }
    }
  }
`;

export const ConversationInner = styled.div`
  max-height: calc(100vh - 335px);
  overflow-y: auto;

  > div {
    > div {
      > div {
        > ul.str-chat__ul {
          > li {
            > .str-chat__date-separator {
              padding: 10px 10px;
            }

            > div {
              > .str-chat__avatar {
                > .str-chat__avatar-fallback {
                  background: #B54F3E;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const chatApiKey = 'gbdwud64pcpv';
const chatUserId = '1';
const chatUserToken = localStorage.getItem('chatToken');
const chatUserName = '1';

const config = {
    chat: {
        apiKey: chatApiKey,
        userId: chatUserId,
        userToken: chatUserToken,
        userName: chatUserName,
    },
};

const userId = config.chat.userId

const sort = {last_message_at: -1};
const filters = {
    type: 'messaging',
    members: {$in: [userId]},
};
const options = {
    limit: 10,
};

const ChannelHeader2 = () => {
    return (
        <div>Header</div>
    );
}


function CustomChannelHeader({user}) {
    if (!user) {
        return null;
    }
    const localDate = new Date(user.last_active);


    return <div>
        {user.name}
        <br/>Last Seen: {localDate.toString()}
        <br/><a href={`/itineraries?filters={"user":"${user.id}"}`} target='_blank'>View Itineraries</a>
    </div>;
}

CustomChannelHeader.propTypes = {user: PropTypes.any};

export const getInitials = (name) => {
    // Max 2 Letter initials
    if (!name) {
        return '';
    }
    return name.split(' ').map((n) => n[0]).slice(0, 2).join('')
}

const ListChats = () => {

    const [params, setURLSearchParams] = useSearchParams();

    const id = params.get('id');

    const [channel, setChannel] = useState(null);



    const {data} = useContext(AppContext);
    const token = localStorage.getItem('chatToken');
    const user = {
        id: `${data?.user?.id}`,
        name: data?.user?.name,
        image: `https://getstream.io/random_png/?id=${data?.user?.id}&name=${data?.user?.name}`,
    };


    const chatClient = useChatClient({
        apiKey: config.chat.apiKey,
        user,
        tokenOrProvider: token,
    });


    const [activeUser, setActiveUser] = useState(null);

    const CustomChannelPreview = (props) => {

        useEffect(() => {
            if(props.activeChannel?.data?.created_by) {
                setActiveUser(props.activeChannel?.data?.created_by)
            }
        }, [props.activeChannel?.data?.created_by]);

        return <ChannelPreview activeChannel={channel} setActiveChannel={newChannel=>{
            setChannel(newChannel);
        }} channel={props.channel}/>;
    };

    useEffect(() => {
        if(activeUser) {
            setURLSearchParams({id: activeUser?.id});
        }
    }, [activeUser]);

    useEffect(() => {
        console.log({id, chatClient})
        if (!id || !chatClient) {
            return;
        }
        if(id === activeUser?.id) {
            return;
        }
        const getChannel = async () => {
            const channel = chatClient.channel('messaging', '', {members: ['1', `${id}`]});
            await channel.create();
            setChannel(channel);
            setActiveUser(channel.data.created_by);
        };
        getChannel();
    }, [id, chatClient])

    if (!chatClient) {
        return <LoadingIndicator/>;
    }



    return (
        <Chat client={chatClient} theme='str-chat__theme-light'>
            <ChatContainer>
                <AllMessages>
                    <ChannelList customActiveChannel={channel?.id} setActiveChannelOnMount={false} Preview={CustomChannelPreview} filters={filters}
                                 sort={sort} options={options}/>
                </AllMessages>
                <Channel channel={channel}>
                    <Window>
                        <Header>
                            <CustomChannelHeader user={activeUser}/>
                        </Header>
                        <ConversationInner>
                            <MessageList/>
                        </ConversationInner>
                        <MessageInput/>
                    </Window>
                    <Thread/>
                </Channel>
            </ChatContainer>
        </Chat>
    );
};

export default ListChats;

