import React, {useContext} from 'react';
import styled from "styled-components";
import AppTheme from '../../../../themes/AppTheme';

// Assets
import DashActive from '../../../../images/menu-icons/dashboard-active.svg';
import DashInActive from '../../../../images/menu-icons/dashboard-inactive.svg';
import ChatActive from '../../../../images/menu-icons/chat-active.svg'
import ChatInactive from '../../../../images/menu-icons/chat-inactive.svg';
import ProjectInactive from '../../../../images/menu-icons/project-inactive.svg';
import SecurityInactive from '../../../../images/menu-icons/secure-inactive.svg';
import AccountInactive from '../../../../images/menu-icons/account-inactive.svg';
import Useractive from '../../../../images/menu-icons/user-active.svg';
import UserInactive from '../../../../images/menu-icons/user-inactive.svg';
import HelpInactive from '../../../../images/menu-icons/help-inactive.svg';
import DepartmentActive from '../../../../images/menu-icons/department-active.svg';
import DepartmentInactive from '../../../../images/menu-icons/department-inactive.svg';
import NavigationItems from './ChildComponents/NavigationItems';
import {AppContext} from "../../../../context/AppContext";

export const ModuleBoxes = styled.div``;

export const NavBar = styled.ul`
 margin:0;
 padding:10px 0;
 list-style:none;
 display:flex;
 flex-direction:column;
 justify-content:flex-start;
 flex:1;
 max-height:calc(100vh - 200px);
 overflow:auto;

 &.border-bottom{
    border-bottom:1px solid #98A2B3 !important;
    padding:40px 0;
 }
 &.space-around{
    padding-top:30px;
 }
`;

export const NavBarItem = styled.li`
    padding:0px 16px 4px 16px;
    position:relative;
    &.active {
        >a{
            background-color: ${AppTheme.header.colors.whiteColor};
            border-radius: 6px;
            padding: 8px;
            >span{
                color:${AppTheme.header.colors.blackColor};
                font-weight: 600;
            }
            >.inactive-icons{
                display:none;
            }
            >.active-icons{
                display:flex;
                width:20px;
            }           
        }
    }
    >a{
        text-decoration:none;
        color:${AppTheme.header.colors.whiteColor};
        height:40px;
        display:flex;
        align-items:center;
        width:100%;
        padding:8px;
        >.active-icons{
            display:none;
        }
        >.inactive-icons{
            display:flex;
            width:20px;
        }
    }
    &:last-child{
        border-bottom:none;
    }
`;

export const UnreadBadge = styled.span`
    background-color: #00BB07;
    color: white;
    border-radius: 150px;
    padding: 2px 13px;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top: 9px;
    margin-left: -10px;
`;

const SidebarListItem = () => {


    const {data} = useContext(AppContext);



    const getMenuItems = () => {
        return [
            {
                url: '/dashboard',
                tabname: 'Dashboard',
                inactiveIcon: DashInActive,
                activeIcon: DashActive,
            },
            {
                url: '/users',
                tabname: 'Users',
                inactiveIcon: UserInactive,
                activeIcon: Useractive,
            },
            {
                url: '/itineraries',
                tabname: 'Itineraries',
                inactiveIcon: DepartmentInactive,
                activeIcon: DepartmentActive,
            },
            {
                url: '/chats',
                tabname: 'Chats',
                inactiveIcon: ChatActive,
                activeIcon: ChatInactive,
                unreadCount: data?.unread?.total || 0,
            },
        ];
    };

    return (
        <ModuleBoxes>
            <NavBar className='border-bottom'>
                {getMenuItems().map((item, index) => (
                    <NavBarItem key={index} className={item.url === window.location.pathname ? 'active' : ''}>
                        <NavigationItems
                            url={item.url}
                            tabname={item.tabname}
                            inactiveIcon={item.activeIcon}
                            activeIcon={item.inactiveIcon}
                        />
                        {item.unreadCount > 0 && (
                            <UnreadBadge>{item.unreadCount}</UnreadBadge>
                        )}
                    </NavBarItem>
                ))}
            </NavBar>

        </ModuleBoxes>
    );
};

export default SidebarListItem;
