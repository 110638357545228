import React, {useContext, useState} from "react";
import Form from "../../components/form/Form";
import Api from "../../helpers/Api";
import AuthLayout from "../../components/layout/AuthLayout";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import {AppContext} from "../../context/AppContext";

export const Container = styled.div`
    width:100%;
    float:left;
    padding: 30px;
`;

export const Heading = styled.div`
  margin-bottom: 16px;
    
  > span {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    color: ${AppTheme.header.colors.blackColor};
    text-align:left;
    display:block;
  }
`;
export const FormContainer = styled.div`
  >div{
    >form{
        >div{
            >.simple-button{
                width:100%;
                justify-content:center;
            }
        }
    }
  }
`;

const Login = () => {

    const {data, setData} = useContext(AppContext);

    const [formData, setFormData] = useState({email: '', password: ''});
    const [loading, setLoading] = useState(false)

    const fields = [
        {name: 'email', label: 'Email', type: 'email', placeholder: 'Enter email'},
        {name: 'password', label: 'Password', type: 'password' ,placeholder: 'Enter password'}
    ];

    const handleSubmit = ()=>{
        setLoading(true);
        Api.post('/auth/login', formData).then(response => {
            localStorage.setItem('token', response.token);
            localStorage.setItem('chatToken', response.chatToken);
            window.location = '/';
        }).catch(() => {
            alert('Error');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <AuthLayout title={'Login'} description={'Welcome to Agency Maison! Please Login.'}>
            <Container>
                <FormContainer>
                    <Form
                        fields={fields}
                        onSubmit={handleSubmit}
                        submitting={loading}
                        data={formData}
                        handleChange={d => setFormData(d)}
                    />
                </FormContainer>
                <div style={{textAlign:'left', marginTop:'50px'}}>
                    Forgot Password? <a href="/auth/forgot">Click Here</a>
                </div>
            </Container>
        </AuthLayout>
    );
}

export default Login;
