import React, {useEffect, useRef, useState} from 'react';
import DeleteIcon from '../../images/delete-icon.svg';
import EditIcon from '../../images/edit-icon.svg';
import ViewIcon from '../../images/view-icon.svg';
import DownIcon from '../../images/down-arrow.svg';
import Api from "../../helpers/Api";
import {get} from "lodash/object";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';

export const TableContainer = styled.table`
    width:100%;
    border-radius:0px;
    background:${AppTheme.header.colors.whiteColor};
    border: 1px solid ${AppTheme.header.colors.tableBorder}`;

export const TableHeading = styled.thead`
    >tr{
        >th{
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            padding:12px 18px;
            color:${AppTheme.header.colors.blackColor};
            border-bottom: 1px solid #EAECF0;
            background: ${AppTheme.header.colors.bodyBackground};
            text-align:left;
            &:last-child{
                text-align:center;
            }
        }
    }
`;

export const TableBody = styled.tbody`
    >tr{
        >td{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; 
            color:${AppTheme.header.colors.blackColor};
            border-bottom: 1px solid ${AppTheme.header.colors.tableBorder} ;
            padding:12px 18px;
            text-align:left;

            &:last-child{
                text-align:center;
            }
        }
        &:last-child{
            >td{
                border-bottom:none;
            }
        }
    }
`;

export const ActionButton = styled.div`
display:flex;
flex-direction:column;
align-items:center;
   >button{
        height:40px;
        border-radius: 0px;
        border: none;
        background:transparent;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color:${AppTheme.header.colors.blueColor};
        padding:0 0px;
        cursor:pointer;
        min-width:110px;
        >span{
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            color:${AppTheme.header.colors.blueColor};
            padding-right:10px;
        }
        .drop-icon{
            height:40px;
            display:flex;
            align-items:center;
            justify-content:center;
            border-left:none;
            padding-left:0px;
        }
   }
   >.show-drop {
        border-radius: 8px;
        border: 1px solid ${AppTheme.header.colors.tableBorder};
        background: ${AppTheme.header.colors.whiteColor};
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        position: absolute;
        margin-top:42px;
        width:150px;
        >ul{
            list-style:none;
            margin:0;
            padding:10px 0px;
            >li{
                display:flex;
                align-items:flex-start;
                justify-content:flex-start;
                padding:0px 10px;
                >button{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    color: ${AppTheme.header.colors.blackColor};
                    padding:10px 8px;
                    width:100%;
                    display:flex;
                    align-items:flex-start;
                    justify-content:flex-start;
                    cursor:pointer;
                    background:transparent;
                    border:none;
                    >img{
                        margin-right:10px;
                        margin-top:2px;
                    }

                    &:hover{
                        border-radius: 6px;
                        background: #F9FAFB;
                    }
                }
                >a{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    color: ${AppTheme.header.colors.blackColor};
                    padding:10px 8px;
                    width:100%;
                    display:flex;
                    align-items:flex-start;
                    justify-content:flex-start;
                    cursor:pointer;
                    text-decoration:none;
                    >img{
                        margin-right:10px;
                        margin-top:2px;
                    }

                    &:hover{
                        border-radius: 6px;
                        background: #F9FAFB;
                    }
                }
            }
        }
    }
`;

const Table = ({columns, data, sortColumn, endpoint, refresh, actions = []}) => {

    const [showDrop,setShowDrop] = useState(false);

    const dropdownRefs = useRef({});

    useEffect(() => {
        // Event listener to close dropdown when clicked outside of it
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event) => {
        Object.keys(dropdownRefs.current).forEach((key) => {
          if (dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)) {
            dropdownRefs.current[key] = false;
          }
        });
        setShowDrop({ ...dropdownRefs.current });
    };
    const toggleDropdown = (id) => {
        dropdownRefs.current[id] = !dropdownRefs.current[id];
        setShowDrop({ ...dropdownRefs.current });
    };

    return (
        <TableContainer>
            <TableHeading>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index}>
                            {column.label}
                            {sortColumn && sortColumn.key === column.key && (sortColumn.order === 'asc' ? '▲' : '▼')}
                        </th>
                    ))}
                    <th>Actions</th>
                </tr>
            </TableHeading>
            <TableBody>
                {data.map((row, index) => (
                    <tr key={index}>
                        {columns.map((column, index) => (
                            <td key={index}>{column.render ? column.render(row) : get(row,column.key)}</td>
                        ))}
                        <td>
                            <ActionButton>
                                <button onClick={() => toggleDropdown(row.id)}>
                                    <span>Take Action</span>
                                    <div className='drop-icon'>
                                        <img src={DownIcon} alt="Down icon " />
                                    </div>
                                </button>
                                {dropdownRefs.current[row.id] && (
                                    <div className='show-drop' ref={(node) => (dropdownRefs.current[row.id] = node)}>
                                        <ul>
                                            <li>
                                                <a  href={`${endpoint}/${row.id}`}>
                                                    <img src={ViewIcon} alt='View icon' />
                                                    View
                                                </a>
                                            </li>
                                            <li>
                                                <a  href={`${endpoint}/${row.id}/edit`}>
                                                    <img src={EditIcon} alt='Edit icon' />
                                                    Edit
                                                </a>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={()=>{
                                                        window.confirm('Are you sure?') && Api.delete(`${endpoint}/${row.id}`)
                                                            .then(() => {
                                                                refresh();
                                                            })
                                                            .catch(() => {
                                                                alert('Error');
                                                            });
                                                    }}
                                                >
                                                    <img src={DeleteIcon} alt='Edit icon' />
                                                    Delete
                                                </button>
                                            </li>
                                            {
                                                actions.map((action, index) => (
                                                    <li key={index}>
                                                        <button onClick={() => action.action(row)}>
                                                            <img src={action.icon} alt={`${action.label} icon`} />
                                                            {action.label}
                                                        </button>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                )}
                            </ActionButton>
                        </td>
                    </tr>
                ))}

            </TableBody>
        </TableContainer>
    );
}

export default Table;
