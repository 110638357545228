import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Api from "../../../helpers/Api";
import config from "../../../config/config";
import {get} from "lodash/object";
import AppLayout from "../../../components/layout/AppLayout";
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';
import SubHeader from "../../../components/subheader/SubHeader";

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0;
    >form{
        background:${AppTheme.header.colors.whiteColor};
        padding:0px 15px 20px 15px;
    }
`;

export const FormGroup = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};
  width:100%;
  float:left;
  >.view-col{
    width:100%;
    float:left;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    > label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 0px;
        text-align: left;
        width: 280px;
        padding-right: 20px;
    }
    >span{
        border-radius: 2px;
        border: 1px solid ${AppTheme.header.colors.inputBorder};
        background: ${AppTheme.header.colors.whiteColor};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height: 50px;
        color: ${AppTheme.header.colors.blackColor};
        background: transparent;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        outline: none;
        width: 100%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
  }
`;

const ViewUser = () => {
    const {id,entity} = useParams();
    const entityConfig = config.entities[entity];
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        Api.get(`/${entity}/${id}`)
            .then(response => {
                setUser(response);
            })
            .catch(() => {
                alert('Error');
            }).finally(() => {
                setLoading(false);
        })
    }, []);

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    href={entityConfig.pages.view.showCreate === true  ?`/${entity}/create`: null}
                    label={`Create ${entityConfig.labels.singular}`}
                    heading={`View ${entityConfig.labels.singular}`}
                />
            {loading && <div>Loading...</div>}
            {error && <div>Error</div>}
            <form>
                {user && (
                    <>{entityConfig.pages.view.render ? entityConfig.pages.view.render() :
                        entityConfig.pages.view.fields.map(field => (
                            <FormGroup>
                                <div className="view-col" key={field.name}>
                                    <label>{field.label}: </label>
                                    <span>{get(user, field.key)}</span>
                                </div>
                            </FormGroup>
                        ))}
                    </>
                )}
            </form>

            </Container>
        </AppLayout>
    );
}

export default ViewUser;
