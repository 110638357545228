import React, {useEffect, useState} from "react";
import Api from "../../helpers/Api";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import ArrowIcon from '../../images/drop-black.svg';

export const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom: 0px;
    text-align: left;
    width: 280px;
    padding-right: 20px;
  }
`;

export const SelectBox = styled.select`
  border-radius: 8px;
  border: 1px solid ${AppTheme.header.colors.inputBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #344054;
  padding: 0 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 8px center;
  width: 165px;
`;

const DynamicSelect = ({value, onChange, name, options, required = false}) => {
    const [_options, setOptions] = useState([]);
    useEffect(() => {
        Api.get(`${options.endpoint}`)
            .then(response => {
                setOptions(response.map(user => ({
                    value: user[options.value],
                    label: user[options.label]
                })));
            });
    }, []);
    return (
        <HorizontalContainer>
            <SelectBox value={value} required={required} onChange={onChange} name={name}
                style={{
                    backgroundImage: `url('${ArrowIcon}')`,
                }}
            >
                <option value="">Select </option>
                {_options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </SelectBox>
        </HorizontalContainer>
    );
}

export default DynamicSelect;
