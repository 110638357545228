import React, {useState} from 'react';
import DynamicSelect from "./DynamicSelect";
import styled from "styled-components";
import ArrowIcon from '../../images/drop-black.svg';
import AppTheme from '../../themes/AppTheme';
import Api from "../../helpers/Api";
import axios from "axios";
import FileIcon from '../../images/file.svg';
import UploadIcon from '../../images/upload.svg';
import CloseIcon from '../../images/error-close.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;

  > label {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom: 8px;
    text-align: left;
  }
`;

export const TextArea = styled.textarea`
  border-radius: 8px;

  border: none;
  background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.inputBackground}`)}!important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 200px;
  color: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorTextColor}` : `${AppTheme.header.colors.blackColor}`)};
  background: transparent;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  outline: none;
  width: 100%;
  padding: 0 10px;

  &::placeholder {
    color: ${AppTheme.header.colors.placeholderColor};
    font-weight: 400;
  }
`;

export const DateContainer = styled.input`
  border-radius: 2px;
  border: 1px solid ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.inputBorder}`)};
  background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.whiteColor}`)};
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 50px;
  color: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorTextColor}` : `${AppTheme.header.colors.blackColor}`)};
  background: transparent;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  outline: none;
  width: 100%;
  padding: 0 10px;

  &::placeholder {
    color: ${AppTheme.header.colors.placeholderColor};
    font-weight: 400;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  border-radius: 8px;
  background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.inputBackground}`)};
  height: 48px;
  width: 100%;

  > input {
    border: none;
    height: 48px;
    color: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorTextColor}` : `${AppTheme.header.colors.blackColor}`)};;
    background: transparent;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    width: 100%;

    &::placeholder {
      color: ${AppTheme.header.colors.placeholderColor};
      font-weight: 600;
    }
  }

  > .icon {
    margin-right: 12px;
  }
`;

export const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${AppTheme.header.colors.blackColor};
    margin-bottom: 0px;
    text-align: left;
    width: 280px;
    padding-right: 20px;
  }

  > input {
    border-radius: 2px;
    border: 1px solid ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.inputBorder}`)};
    background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.whiteColor}`)};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 50px;
    color: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorTextColor}` : `${AppTheme.header.colors.blackColor}`)};
    background: transparent;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    width: 100%;
    padding: 0 10px;

    &::placeholder {
      color: ${AppTheme.header.colors.placeholderColor};
      font-weight: 400;
    }
  }

  > .add-more {
    width: 100%;
    display: flex;
    flex-direction: column;

    > .add-more-col {
      width: 100%;
      display: flex;
      flex-direction: column;

      > input {
        border-radius: 2px;
        border: 1px solid ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.inputBorder}`)};
        background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.whiteColor}`)};
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height: 50px;
        color: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorTextColor}` : `${AppTheme.header.colors.blackColor}`)};
        background: transparent;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        outline: none;
        width: 100%;
        padding: 0 10px;

        &::placeholder {
          color: ${AppTheme.header.colors.placeholderColor};
          font-weight: 400;
        }
      }

      > span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${AppTheme.header.colors.blueColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding-top: 7px;
        cursor: pointer;

        > img {
          margin-right: 8px;
        }
      }

      > span.remove {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 3px;
        padding-bottom: 10px;
        color: ${AppTheme.header.colors.errorTextColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;
      }
    }

  }

  > textarea {
    border-radius: 2px;
    border: 1px solid ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.inputBorder}`)};;
    background: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorBackground}` : `${AppTheme.header.colors.whiteColor}`)};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 128px;
    color: ${(props) => (props.hasError ? `${AppTheme.header.colors.errorTextColor}` : `${AppTheme.header.colors.blackColor}`)};
    background: transparent;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    width: 100%;
    padding: 10px 10px 0px 10px;

    &::placeholder {
      color: ${AppTheme.header.colors.placeholderColor};
      font-weight: 400;
    }
  }
`;

export const SelectBox = styled.select`
  border-radius: 0px;
  border: 1px solid ${AppTheme.header.colors.inputBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #344054;
  padding: 0 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 8px center;
  width: 100%;
`;

export const ErrorContainer = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: -0.12px;
  color: ${AppTheme.header.colors.errorTextColor};
  margin-top: 8px;
`;

export const FilePreviewContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  background:#efefef;
  border-radius:4px;
  padding:10px;
  margin-bottom:10px;
  >a{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    text-decoration:none;
    cursor:pointer;
    >img {
      width:24px;
      height:24px;
      margin-right:10px;
    }
    >span{
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 100% */
      letter-spacing: -0.12px;
      color: ${AppTheme.header.colors.blackColor};
    }
  }
  >button{
    height:auto;
    border:none;
    background:transparent;
    >img{
      width:26px;
      height:26px;
    }
  }
`;

export const UploaderContainer = styled.div`
  
  
  margin-bottom:10px;
  >label{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    background:#efefef;
    border-radius:4px;
    padding:10px;
    >img {
      width:24px;
      height:24px;
      margin-right:10px;
    }
    >span{
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 100% */
      letter-spacing: -0.12px;
      color: ${AppTheme.header.colors.blackColor};
    }
  }
`;

const FileUploader = ({value, onChange, name}) => {
    const [loading, setLoading] = useState(false);

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        if (!files.length) return;

        setLoading(true); // Show loader
        const uploadedFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            try {
                // Fetch the pre-signed URL from your server
                const {url, key} = await Api.post('/media/uploads', {name: file.name, type: file.type});

                // Upload the file to the S3 pre-signed URL
                await axios.put(url, file, {
                    headers: {
                        'Content-Type': file.type,
                    },
                });

                onChange(
                    {
                        target: {
                            name,
                            value: [...value, {size: file.size, name: file.name, key}]
                        }
                    }
                )

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        setLoading(false); // Hide loader
    };

    return (
        <div>
          <UploaderContainer>
            <label htmlFor="fileUpload" className="file-label">
              <img src={UploadIcon} />
              <span>Click here to upload files</span>
            </label>
            <input
                id="fileUpload"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }} // Hides the actual file input
            />
          </UploaderContainer>
            {/* <input type="file" onChange={handleFileChange}/> */}
            {loading && <div className="loader">Uploading...</div>}
        </div>
    );
};


const Input = ({type, value, onChange, placeholder, Icon, name, error, options, label, required = false}) => {
    const renderInput = () => {
        switch (type) {
            case 'textarea':
                return <TextArea hasError={error} value={value} onChange={onChange} name={name}/>
            case 'select':
                if (options.type === 'dynamic') {
                    return <DynamicSelect value={value} onChange={onChange} name={name} options={options}
                                          required={required}/>;
                }
                return (
                    <SelectBox value={value} required={required} onChange={onChange} name={name}
                               style={{
                                   backgroundImage: `url('${ArrowIcon}')`,
                               }}
                    >
                        <option value="">Select</option>
                        {options && options.options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </SelectBox>
                );
            case 'date':
                return <DateContainer type="date" value={value} onChange={onChange} name={name}/>;
            case 'boolean':
                return <input type='checkbox' checked={!!value} onChange={e => {
                    onChange({
                        target: {
                            name,
                            value: e.target.checked
                        }
                    });
                }} name={name} placeholder={placeholder}/>

            case 'datetime':
                return <DateContainer type="datetime-local" value={value} onChange={onChange} name={name}/>;
            case 'attachments':
                return <div>
                    <div>
                        {
                            value.map(v => {
                                return <FilePreviewContainer>
                                    <a href={`/${v.key}`} target='_blank'>
                                      <img src={FileIcon} />
                                      <span>{v.name}</span>
                                    </a>
                                    <button onClick={() => {
                                        const files = value.filter(f => f.file !== v.file);
                                        onChange({
                                            target: {
                                                name,
                                                value: files
                                            }
                                        });
                                    }}><img src={CloseIcon} />
                                    </button>
                                </FilePreviewContainer>
                            })
                        }

                        <FileUploader name={name} value={value} onChange={onChange}/>
                    </div>
                </div>
            case 'multiselect':
                return options && options.map(option => (
                    <label key={option.value}>
                        <input
                            type="checkbox"
                            name={name}
                            value={option.value}
                            checked={value.includes(option.value)}
                            onChange={onChange}
                        />
                        {option.label}
                    </label>
                ));
            default:
                return <InputContainer hasError={error} className='simple-input'>
                    {Icon &&
                        <img className='icon' src={Icon} alt='icon'/>
                    }
                    <input type={type} value={value} onChange={onChange} name={name} placeholder={placeholder}/>
                </InputContainer>;
        }
    };

    return (
        <Container>
            {label && <label htmlFor={name}>{label}</label>}
            {renderInput()}
            {error && <ErrorContainer>{error}</ErrorContainer>}
        </Container>
    );
};

export default Input;
