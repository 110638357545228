import React, {useEffect, useState} from "react";
import Api from "../../helpers/Api";
import Button from "./Button";
import {useNavigate} from "react-router-dom";
import Input from "./Input";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';

export const Container = styled.div`
    width:100%;
    float:left;
    >form{
        >div
    }
`;

export const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
`;

export const InputContainer = styled.div`
    width:100%;
    float:left;
    display: flex;
    flex-direction: column;
    > label {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: ${AppTheme.header.colors.blackColor};
        margin-bottom: 8px;
        text-align: left;
    }
`;

const Form = ({
                  fields,
                  placeholder,
                  onSubmit,
                  data,
                  handleChange,
                  endpoint,
                  id,
                  redirectTo,
                  hideSubmit,
                  renderFormTag = true,
                  submitting,
    onCreate
              }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            Api.get(`${endpoint}/${id}`)
                .then(response => {
                    let d = {};
                    fields.forEach(field => {
                        d[field.name] = field.serverToClient ? field.serverToClient(response) : response[field.name];
                    });
                    handleChange(d);
                })
                .catch(() => {
                    alert('Error');
                });
        }
    }, []);
    const _onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (onSubmit) {
            onSubmit(data);
            return;
        }
        setLoading(true);
        //Only post keys in fields
        const d = {};
        fields.forEach(field => {
            d[field.name] = data[field.name];
        });

        const promise = id ? Api.put(`${endpoint}/${id}`, d) : Api.post(endpoint, d);

        promise.then((response) => {
            if(onCreate){
                onCreate(response);
                return;
            }
            navigate(redirectTo || endpoint);
        })
            .catch(() => {
                alert('Error');
            }).finally(() => {
            setLoading(false);
        })
    }

    const _handleChange = (e) => {
        const {name, value} = e.target;
        const d = {...data, [name]: value};
        handleChange(d);
    }
    const renderFields = (fields) => {
       return fields.map(field => (
                <InputContainer key={field.name}>
                    <label htmlFor={field.name}>{field.label}</label>
                    {field.render && field.render(data, v => {
                        const d = {...data, [field.name]: v};
                        handleChange(d);
                    })}
                    {!field.render && <Input
                        id={field.name}
                        name={field.name}
                        type={field.type}
                        value={data[field.name]}
                        required={field.required === true}
                        onChange={_handleChange}
                        options={field.options}
                        placeholder={field.placeholder}
                    />}
                </InputContainer>
            ))

    }

    return (
        <Container>
            {renderFormTag ?
                <form onSubmit={_onSubmit}>
                    {renderFields(fields)}
                    {!hideSubmit &&
                        <ButtonContainer>
                            <Button type="submit" disabled={loading || submitting} loading={loading || submitting}>Submit</Button>
                        </ButtonContainer>
                    }
                </form>
                :
                renderFields(fields)
            }

        </Container>
    );
}

export default Form;
