import React, {useEffect, useState} from "react";
import styled from "styled-components";

// Assets
import AppTheme from "../../themes/AppTheme";

export const HeaderContainer = styled.div`
    width:100%;
    background: ${AppTheme.header.colors.whiteColor};
    height:72px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 32px;
`;

export const LogoContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
    >img{
        width:28px;
        height:32px;
        margin-right:12px;
    }
    >span{
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color:${AppTheme.header.colors.blackColor};
        text-transform:uppercase;
    }
`;

export const SelectContainer = styled.div`
    display:flex;
    align-items:flex-end;
    justify-content:flex-end;
    
`;

const Header = () => {

    return (
        <HeaderContainer>
            <LogoContainer>
                <span></span>
            </LogoContainer>
        </HeaderContainer>
    );
}

export default Header;
