import React, {useContext, useEffect, useState} from 'react'
import styled from "styled-components";
import Logo from '../../../images/white-logo.svg';
import {Link} from 'react-router-dom';
import AppTheme from '../../../themes/AppTheme';

// Assets
import LogoutIcon from '../../../images/logout-white.svg';
import SidebarListItem from './SubComponents/SidebarListItem';
import {AppContext} from "../../../context/AppContext";
import {getInitials} from "../../chats/ListChats";
import {useChatClient} from "../../../hooks/useChatClient";

import { initializeApp } from "firebase/app";
import {getMessaging, getToken, onMessage } from "firebase/messaging";

export const SidebarContainer = styled.div`
  width: 267px;
  background: ${AppTheme.header.colors.blueColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid rgba(9, 30, 66, 0.141176);
  float: left;
  height: calc(100vh - 0px);
  @media screen and (max-width: 1240px) {
    width: 220px;
  }

`;

export const LogoContainer = styled.div`
  width: 100%;
  padding: 15px 0px;
  text-align: left;

  > a {
    > img {
      width: 100%;
    }
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width:100%;
`

export const ProfileContainer = styled.div`
  padding-right: 10px;

  > a {
    cursor: pointer;
    background: ${AppTheme.header.colors.whiteColor};
    width: 32px;
    height: 32px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${AppTheme.header.colors.blackColor} !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > h4 {
      color: ${AppTheme.header.colors.whiteColor};
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0px;
      text-align:left;
    }

    > a {
      cursor: pointer;
    }
  }

  > span {
    color: ${AppTheme.header.colors.whiteColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align:left;
    display:block;
    word-break: break-all;
  }
`;

export const NavigationBox = styled.div`
`;

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDx8UMrSX7-13MM0ouXSdgsflrTYD0SQXg",
    authDomain: "agency-maison.firebaseapp.com",
    projectId: "agency-maison",
    storageBucket: "agency-maison.appspot.com",
    messagingSenderId: "337976027417",
    appId: "1:337976027417:web:1fbdc016b510e883973d88",
    measurementId: "G-V7RLMC80C7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const Sidebar = () => {
    const {data, setData} = useContext(AppContext);

    const chatApiKey = 'gbdwud64pcpv';
    const chatUserId = '1';
    const chatUserToken = localStorage.getItem('chatToken');
    const chatUserName = '1';

    const [unread, setUnread] = useState({
        total: 0, channels: [], loading: true
    })

    const config = {
        chat: {
            apiKey: chatApiKey,
            userId: chatUserId,
            userToken: chatUserToken,
            userName: chatUserName,
        },
    };
    const user = {
        id: `${data?.user?.id}`,
        name: data?.user?.name,
        image: `https://getstream.io/random_png/?id=${data?.user?.id}&name=${data?.user?.name}`,
    };
    const token = localStorage.getItem('chatToken');

    const chatClient = useChatClient({
        apiKey: config.chat.apiKey,
        user,
        tokenOrProvider: token,
    });

    const registerPushToken = async () => {

        Notification.requestPermission().then(async (permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                getToken(messaging, {vapidKey: 'BELYug3eywZypThp__wrwV3DMhZdkkIG5a2fTvhRkliLhuDGGzFtElw7kvN0LsXEkkgdFFlvH36bJTTJp-9bOko'}).then(token=>{
                    chatClient.addDevice(token, 'firebase', `${data?.user?.id}`, 'FCM').then((response) => {
                        console.log({token});
                        console.log('Device added', response);
                        onMessage(messaging, (payload) => {
                            console.log('Message received. ', payload);
                        });
                    });
                })

            } else {
                console.log('Unable to get permission to notify.');
            }
        });
    };

    const getUnread = () => {
        if (!chatClient) {
            return
        }
        chatClient.getUnreadCount(`${data?.user?.id}`).then((unread) => {
            setUnread(current=>({
                total: unread.total_unread_count,
                channels: unread.channels,
                loading: false
            }))
        })
    }

    useEffect(() => {
        setData(d=>({
            ...d,
            unread
        }))
    }, [unread]);


    useEffect(() => {
        if(!chatClient){
            return
        }
        getUnread();
        registerPushToken();
        chatClient.on(event => {
            console.log({event})
            if (event.total_unread_count !== null || event.unread_channels !== null) {
                getUnread();
            }
        });


    }, [chatClient])


    return (
        <SidebarContainer>
            <NavigationBox>
                <LogoContainer>
                    <Link>
                        <img src={Logo} alt="Logo"/>
                    </Link>
                </LogoContainer>

                {/* Sidebar Navigation */}
                <SidebarListItem/>
            </NavigationBox>

            {/* Logout */}
            <LogoutContainer>
                <ProfileContainer>
                    <a
                        onClick={() => {
                            window.location.href = '/profile';
                        }}
                    >
                        {getInitials(data?.user?.name)}
                    </a>
                </ProfileContainer>
                <InfoContainer>
                    <div className='col'>
                        <h4>{data?.user?.name}</h4>
                        <a
                            onClick={() => {
                                localStorage.clear();
                                window.location.href = '/auth/login';
                            }}
                        >
                            <img src={LogoutIcon} alt='Logout icon'/>
                        </a>
                    </div>
                    <span>{
                        data?.user?.email
                    }</span>
                </InfoContainer>
            </LogoutContainer>
        </SidebarContainer>
    )
}

export default Sidebar;
