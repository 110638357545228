import React, {useState} from "react";
import Form from "../../components/form/Form";
import Api from "../../helpers/Api";
import AuthLayout from "../../components/layout/AuthLayout";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';

export const Container = styled.div`
    width:65%;
    float:left;
`;

export const Heading = styled.div`
  margin-bottom: 16px;
    
  > span {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    color: ${AppTheme.header.colors.blackColor};
    text-align:left;
    display:block;
  }
`;

export const FormContainer = styled.div`
  >div{
    >form{
        >div{
            >.simple-button{
                width:100%;
                justify-content:center;
            }
        }
    }
  }
`;
const ForgotPassword = () => {
    const [data, setData] = useState({email: ''});
    const [done, setDone] = useState(false);

    const fields = [
        {name: 'email', label: 'Email', type: 'email', placeholder: 'Enter email'},
    ];

    const handleSubmit = ()=>{
        Api.post('/auth/password/forgot', data).then(response => {
            setDone(true);
        }).catch(() => {
            alert(`Email doesn't exist`);
        });
    }

    if(done){
        return (
            <AuthLayout>
                <Container>
                    <Heading>
                        <span>Forgot Password</span>
                    </Heading>
                    <div style={{textAlign:'left'}}>An email has been sent to your registered email address with reset instructions.</div>
                </Container>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout>
            <Container>
                <Heading>
                    <span>Forgot Password</span>
                </Heading>
                <FormContainer>
                <Form
                    fields={fields}
                    onSubmit={handleSubmit}
                    data={data}
                    handleChange={d => setData(d)}
                />
                </FormContainer>
            </Container>
        </AuthLayout>
    );
}

export default ForgotPassword;
