import EventBlocks from "../pages/events/EventBlocks";
import ListChats from "../pages/chats/ListChats";
import ListItineraries from "../pages/Itineraries/ListItineraries";
import ChatIcon from '../images/chat.png'


const config = {
    entities: {
        users: {
            labels: {
                singular: 'User',
                plural: 'Users'
            },
            pages: {
                list: {
                    fields: [
                        {label: 'FirstName', key: 'firstName'},
                        {label: 'LastName', key: 'lastName'},
                        {label: 'Email', key: 'email'},
                        {label: 'Phone', key: 'phone'},
                        {label: 'Address Type', key: 'addressType'},
                        {label: 'Address', key: 'address'}
                    ],
                    actions:[
                        {
                            label: 'Chat',
                            action: (user) => {
                                window.location.href = `/chats?id=${user.id}`;
                            },
                            icon: ChatIcon
                        }
                    ]
                },
                create: {
                    fields: [
                        {name: 'firstName', label: 'First Name', type: 'text', defaultValue: ''},
                        {name: 'lastName', label: 'Last Name', type: 'text', defaultValue: ''},
                        {name: 'email', label: 'Email', type: 'email', defaultValue: ''},
                        {name: 'phone', label: 'Phone', type: 'phone', defaultValue: ''},
                        {name: 'addressType', label: 'Address Type', type: 'select',
                            options: {
                                options: ['Home', 'Work'].map(option => ({
                                    label: option,
                                    value: option
                                }))
                            }, defaultValue: 'Home'},
                        {name: 'address', label: 'Address', type: 'textarea', defaultValue: ''},
                    ]
                },
                view: {
                    fields: [
                        {label: 'First Name', key: 'firstName'},
                        {label: 'Last Name', key: 'lastName'},
                        {label: 'Email', key: 'email'},
                        {label: 'Phone', key: 'phone'},
                        {label: 'Address Type', key: 'addressType'},
                        {label: 'Address', key: 'address'}
                    ]
                }
            }
        },
        chats: {
            labels: {
                singular: 'Chat',
                plural: 'Chats'
            },
            pages: {
                list: {
                    render: (data) => {
                        return <ListChats/>
                    },
                    showCreate: false
                }
            }
        },
        itineraries: {
            labels: {
                singular: 'Itinerary',
                plural: 'Itineraries'
            },
            pages: {
                list: {
                    render: (data) => {
                        return <ListItineraries/>
                    }
                },
                create: {
                    fields: [
                        {
                            name: 'user', label: 'User', type: 'select', 'options': {
                                type: 'dynamic',
                                endpoint: '/users',
                                label: 'fullName',
                                value: 'id'
                            },
                            serverToClient: (serverData) => {
                                return serverData.user?.id;
                            }
                        },
                        {name: 'title', label: 'Title', type: 'text'},
                        {name: 'location', label: 'Location', type: 'text'},
                        {name: 'description', label: 'Description', type: 'textarea'},
                        {name: 'from', label: 'From', type: 'date', required: true},
                        {name: 'to', label: 'To', type: 'date', required: true},
                        {
                            name: 'events', label: 'Events', type: 'custom', defaultValue: [
                                {
                                    from: '',
                                    to: '',
                                    title: '',
                                    details: '',
                                    allDay: false,
                                    attachments: []
                                }
                            ], render: (value, onChange) => {
                                return <EventBlocks value={value} onChange={onChange}/>
                            }
                        },
                    ],
                    onCreate: (itinerary) => {
                        window.location.href = `/itineraries/${itinerary.user.id}`;
                    }
                },
                view: {
                    showCreate: true,
                    render: (data) => {
                        return <ListItineraries/>
                    }
                },
            }
        },
    }
}

export default config;
