import React, {useContext, useState} from "react";
import Form from "../../components/form/Form";
import Api from "../../helpers/Api";
import AuthLayout from "../../components/layout/AuthLayout";
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';
import {AppContext} from "../../context/AppContext";
import {useSearchParams} from "react-router-dom";

export const Container = styled.div`
  width:100%;
  float:left;
  padding: 30px;
`;

export const Heading = styled.div`
  margin-bottom: 16px;

  > span {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    color: ${AppTheme.header.colors.blackColor};
    text-align:left;
    display:block;
  }
`;
export const FormContainer = styled.div`
  >div{
    >form{
      >div{
        >.simple-button{
          width:100%;
          justify-content:center;
        }
      }
    }
  }
`;

const Login = () => {

    const {data, setData} = useContext(AppContext);
    const [searchParams] = useSearchParams();

    const [formData, setFormData] = useState({email: '', password: ''});
    const [loading, setLoading] = useState(false)

    const fields = [
        {name: 'password', label: 'Password', type: 'password', placeholder: 'Enter password'},
        {name: 'confirmPassword', label: 'Confirm Password', type: 'password', placeholder: 'Confirm password'}
    ];
    const [done, setDone] = useState(false);

    const handleSubmit = () => {
        if (data.password !== data.confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        const d = {
            password: data.password,
            email: searchParams.get('email'),
            verificationCode: searchParams.get('verificationCode')
        }

        Api.post('/auth/password/reset', d).then(response => {
            setDone(true);
        }).catch(() => {
            alert('Error');
        });
    }

    if(done){
        return (
            <AuthLayout title='Reset Password' description={'Please enter your new password below'}>
                <Container>
                    <FormContainer>
                        <div>
                            <span>
                                Your password has been reset successfully. You can now login with your new password.
                            </span>
                        </div>
                    </FormContainer>
                </Container>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout title='Reset Password' description={'Please enter your new password below'}>
            <Container>
                <FormContainer>
                    <Form
                        fields={fields}
                        onSubmit={handleSubmit}
                        data={data}
                        handleChange={d => setData(d)}
                    />
                </FormContainer>
            </Container>
        </AuthLayout>
    );
}

export default Login;
