import React from 'react'
import styled from "styled-components";
import AppTheme from '../../themes/AppTheme';

// Assets
import Logo from '../../images/new-logo.svg';
import AppleIcon from '../../images/apple-icon.svg';
import AndroidIcon from '../../images/android-icon.svg';

export const Layout = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    flex:1;
    background:#f9f6f1;
    height:100vh;
    @media screen and (max-width: 767px) {
        padding:20px 0;
        height:auto;
    }
`

export const LoginLeft = styled.div`
    width:45%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction:column;
    background:#f9f6f1;
    flex:1;
    padding:20px 20px;
    @media screen and (max-width: 767px) {
        width:90%;
        padding:10px 0px;
    }
`;

export const BasicInfo = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    flex:1;
    width:100%;
    >img{
        margin-bottom:20px;
        @media screen and (max-width: 767px) {
            margin-bottom:10px;
        }
    }
    >h1{
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        color:${AppTheme.header.colors.blackColor};
        margin-bottom:20px;
        text-align:left;
        @media screen and (max-width: 767px) {
            margin-bottom:10px;
        }
    }
    p{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
        color:${AppTheme.header.colors.blackColor};
        margin:0px;
        text-align:left;
    }
`;

export const LoginRight = styled.div`
    width: 80%;
    overflow: auto;
    background: ${AppTheme.header.colors.whiteColor};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:30px;
    border-radius:12px;
    @media screen and (max-width: 767px) {
            width: 100%;
        }
`;

export const GetAppContainer = styled.div`
    padding-top:20px;
    width: 62%;
    margin: 0 auto;
    >span{
        font-size:24px;
        font-weight:400;
        line-height:32px;
        color:#000000;
    }
    >p{
        margin:0;
        font-size:16px;
        font-weight:400;
        line-height:24px;
        color:#000000;
        text-align:center;
        margin-top:15px;

    }
    >.app-link{
        padding-top:15px;
        >a{
            padding:0 10px;
        }
    }
    @media screen and (max-width: 767px) {
        width:100%;
    }
`;

const AuthLayout = ({ children, showLeftPart = true, title, description }) => {

    return <Layout>
        {showLeftPart &&
            <LoginLeft>
                <BasicInfo>
                    <img src={Logo} alt="chitkara-logo" />
                    <h1> {title} </h1>
                    <p>{description}</p>
                    <LoginRight showLeftPart={showLeftPart}>
                        {children}
                    </LoginRight>
                    <GetAppContainer>
                        <span>Get the Agency Maison app!</span>
                        <p>Get the most of Agency Maison by installing the mobile app. You can log in by using the above credentials.</p>
                        <div className='app-link'>
                            <a href={'https://apps.apple.com/app/agency-maison/id6504950210'}>
                                <img src={AppleIcon} alt={'Download from Apple App store'}/>
                            </a>
                            <a href={'https://play.google.com/store/apps/details?id=com.agencymaison.app'}>
                                <img src={AndroidIcon} alt={'Download from Google Play store'} />
                            </a>
                        </div>
                    </GetAppContainer>
                </BasicInfo>

            </LoginLeft>
        }

    </Layout >

}

export default AuthLayout;
