import React, {useState} from "react";
import Form from "../../../components/form/Form";
import {useParams} from "react-router-dom";
import config from "../../../config/config";
import AppLayout from "../../../components/layout/AppLayout";
import styled from "styled-components";
import AppTheme from '../../../themes/AppTheme';
import SubHeader from "../../../components/subheader/SubHeader";

export const Container = styled.div`
    border-radius: 5px;
    border: 1px solid  ${AppTheme.header.colors.tableBorder};
    background:  ${AppTheme.header.colors.whiteColor};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    float:left;
    width:100%;
    padding:0;
`;

export const FormFields = styled.div`
    background:${AppTheme.header.colors.whiteColor};
    padding:0px 15px 20px 15px;
    float: left;
    width: 100%;
    >div{
        >form{
            >div{
                display:flex;
                align-items:flex-start;
                justify-content:flex-start;
                width:100%;
                flex-direction:row;
                padding: 20px 0px;
                border-bottom: 1px solid ${AppTheme.header.colors.inputBorder};
                >label{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    color: ${AppTheme.header.colors.blackColor};
                    margin-bottom: 0px;
                    text-align: left;
                    width: 280px;
                    padding-right: 20px;
                }
                >.simple-button{
                    display:flex;
                    align-items:center;
                    justify-content:flex-end;
                    >button{
                        width:120px;
                        border-radius:4px;
                        padding:0 15px;
                        height:42px;
                    }
                }
                >div{
                    margin-bottom:0px;
                    width:100%;
                    margin-top:0px;
                    
                    >.simple-input{
                        border-radius:2px;
                        background:transparent;
                        height:50px;
                        border:1px solid ${AppTheme.header.colors.inputBorder}
                    }
                    >input{
                        border-radius:2px;
                        height:50px;
                        width:100%;
                        background:transparent;
                        border:1px solid ${AppTheme.header.colors.inputBorder};
                        padding:0px 10px;
                    }
                    >div{
                        >select{
                            border-radius:2px;
                            height:50px;
                            width:100%;
                        }
                        >input{
                            border-radius:2px;
                            height:50px;
                            width:100%;
                            background:transparent;
                        }
                    }
                }
            }
        }
    }
`;

const CreateUser = () => {
    const {id, entity} = useParams();
    const entityConfig = config.entities[entity];

    const fields = entityConfig.pages.create.fields;
    const [data, setData] = useState(fields.reduce((acc, field) => {
        acc[field.name] = field.defaultValue || '';
        return acc;
    },{}));
    console.log({data});
    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={`Create ${entityConfig.labels.singular}`}
                    description={'Set Up New Credential Issuing Departments'}
                />
                <FormFields>
                    <Form onCreate={entityConfig.pages.create.onCreate}
                        fields={fields}
                        endpoint={`/${entity}`}
                        data={data}
                        handleChange={d => setData(d)}
                        id={id}
                    />
                </FormFields>
            </Container>
        </AppLayout>
    );
}

export default CreateUser;
