import React from "react";
import styled from "styled-components";
import Button from "../form/Button";
import AppTheme from '../../themes/AppTheme';

// Assets
import AddIcon from '../../images/add-icon.svg';

export const HeaderContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-direction:row;
    padding:20px 15px;
    background:#ffffff;
    box-shadow:10px 1px 2px 0px rgba(16, 24, 40, 0.06),0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    margin-bottom:35px;
`;

export const ColLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    >h2{
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        color:${AppTheme.header.colors.blackColor};
        margin-bottom:6px;
    }
    >span{
        color:${AppTheme.header.colors.blackColor};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`;

export const InfoCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    >.col{
        display: flex;
        justify-content:flex-start;
        align-items: center;
        margin-bottom:10px;
        >span{
            color:${AppTheme.header.colors.blackColor};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        >strong{
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            color:${AppTheme.header.colors.blackColor};
            padding-left:6px;
        }
    }
`;

export const ColRight = styled.div`
    >div{
        margin-top:0px;
    }
`;

const SubHeader = ({heading,description,href,label,name,email}) => {

    return (
        <HeaderContainer>
            <ColLeft>
                {heading &&
                    <h2>{heading}</h2>
                }
                {description &&
                    <span>{description}</span>
                }
                {email &&
                    <InfoCol>
                        <div className="col">
                            <span>Name: </span>
                            <strong>{name}</strong>
                        </div>
                        <div className="col" style={{marginBottom:0}}>
                            <span>Email: </span>
                            <strong>{email}</strong>
                        </div>
                    </InfoCol>
                }
            </ColLeft>
            {href &&
                <ColRight>
                    <Button href={href}  type='link' icon={AddIcon}>{label}</Button>
                </ColRight>
            }
        </HeaderContainer>
    );
}

export default SubHeader;
