import React from "react";
import AppLayout from "../../../components/layout/AppLayout";
import AppTheme from '../../../themes/AppTheme';
import styled from "styled-components";
import SubHeader from "../../../components/subheader/SubHeader";

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;

`;

const LoadingPage = ({heading, description}) => {


    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={heading}
                    description={description}
                />
                <div>
                    <progress/>
                </div>
            </Container>
        </AppLayout>
    );
}

export default LoadingPage;
