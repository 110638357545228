import React, {useEffect, useState} from "react";
import Api from "../../../helpers/Api";
import {useParams} from "react-router-dom";
import Table from "../../../components/table/Table";
import config from "../../../config/config";
import AppLayout from "../../../components/layout/AppLayout";
import AppTheme from '../../../themes/AppTheme';
import styled from "styled-components";
import SubHeader from "../../../components/subheader/SubHeader";
import LoadingPage from "../../components/loading/LoadingPage";

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;

`;

const ListUsers = () => {
    const {entity} = useParams();
    const entityConfig = config.entities[entity];

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const listUsers = () => {
        if(entityConfig.pages.list.render){
            return;
        }
        setLoading(true);
        const endpoint = entityConfig.pages.list.endpoint || `/${entity}`;
        Api.get(`${endpoint}`).then(response => {
            setUsers(response);
        }).catch(() => {
            alert('Error');
        }).finally(() => {
            setLoading(false);
        });
    }
    useEffect(() => {
        listUsers()
    }, [entity]);

    if (loading) {
        return <LoadingPage heading={`List ${entityConfig.labels.plural}`}
                            description={`View and Organize All ${entityConfig.labels.plural}`}/>
    }

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading={`List ${entityConfig.labels.plural}`}
                    description={`View and Organize All ${entityConfig.labels.plural}`}
                    href={entityConfig.pages.list.showCreate !== false  ?`/${entity}/create`: null}
                    label={`Create ${entityConfig.labels.singular}`}
                />
                {/* <Link to={`/${entity}/create`}>Create {entityConfig.labels.singular}</Link> */}
                <div>
                    {entityConfig.pages.list.render ? entityConfig.pages.list.render() :
                        <Table
                            data={users}
                            endpoint={`/${entity}`}
                            columns={entityConfig.pages.list.fields}
                            refresh={listUsers}
                            actions={entityConfig.pages.list.actions}
                        />
                    }
                </div>
            </Container>
        </AppLayout>
    );
}

export default ListUsers;
